.stack-click-area {
  position: absolute;
  width: 250px;
  height: 250px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
}
