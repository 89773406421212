.player-deck {
  position: fixed;
  bottom: 153px;
}

.deck__image {
  width: 100px;
}

.computer-deck {
  position: fixed;
  top: 0;
}

@media only screen and (min-width: 750px) {
  .computer-deck {
    left: calc(50% - 250px);
  }

  .player-deck {
    left: calc(50% + 150px);
  }
}

@media only screen and (max-width: 750px) {
  .computer-deck {
    left: 50px;
  }

  .player-deck {
    left: auto;
    right: 150px;
  }
}

.deck {
  position: relative;
}

.deck__image {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.deck__counter {
  color: black;
  margin-left: 35px;
  margin-top: 62px;
  font-size: 24px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
