.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: black;
}

.settings__title {
  text-align: center;
  color: grey;
  margin-bottom: 50px;
}

.difficulty-levels {
  display: flex;
  flex-direction: column;
  color: white;
}

.difficulty-levels__level {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.difficulty-levels__level h2{
  margin: 8px;
}

.difficulty-levels__level h3{
  margin: 8px;
}

.difficulty-radio {
  margin-top: 18px;
}
