.stack {
  background-color: rgb(40, 120, 64);
  border-radius: 50%;
}

@media only screen and (min-width: 750px) {
  .stack {
    width: 700px;
    height: 500px;
  }
}

@media only screen and (max-width: 750px) {
  .stack {
    width: 400px;
    height: 400px;
  }
}
