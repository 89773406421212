.card-line {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.card-line__card {
  width: 100/52%;
}

.card-line__card__img {
  width: 100%;
}
