.end-game-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.end-game-screen__background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: dimgrey;
    opacity: 0.7;
}

.end-game-screen__text {
    color: white;
    font-weight: 600;
    font-size: 40px;
    z-index: 1;
}
