.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: black;
}

.about h4 {
  margin: 0 30% 30px 30%;
  color: white;
}

.about h4 a{
  color: white;
}

.about h4 a:visited{
  text-decoration: none;
  color: white;
}

.about__title {
  text-align: center;
  color: grey;
  margin-bottom: 50px;
}
