
.stack-card {
  position: absolute;
  left: 50%;
  top: 50%;
}

.stack-card__content {
}

.stack-card__content-image {
  width: 100px;
}
