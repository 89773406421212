.tutorial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: black;
}

.tutorial__title {
  text-align: center;
  color: grey;
  margin-bottom: 50px;
}
