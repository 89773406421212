.main-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: black;
}

.main-menu__title {
  text-align: center;
  color: grey;
  margin-bottom: 50px;
}

.top-card-line {
  position: fixed;
  top: 0;
}

.bottom-card-line {
  position: fixed;
  bottom: 0;
}
